import React from "react";


const PrivacyPage = () => (
    <>
        <h1>プライバシーポリシー</h1>
        <div className="flex-auto;">
            <p>このプライバシーポリシー（以下「本プライバシーポリシー」といいます。）は、一般社団法人 HyogoICT（以下「当法人」といいます。）がお預かりする個人情報の取扱いについて定めるものです。</p>
            <h3> <strong>個人情報の利用目的</strong></h3>
            <p>当法人は、個人情報を以下の利用目的に示す範囲内で使用します。</p>
            <p>【プログラム参加者の皆さまの個人情報】</p>
            <p>・プログラムの参加者管理</p>
            <p>・プログラムの進行状況の管理</p>
            <p>・皆さまに対する安全管理</p>
            <p>・当法人の活動に関する情報提供</p>
            <p>・当法人又は当法人の支援者が関係する団体が行う各種イベント、キャンペーン等の開催に関する情報提供</p>
            <p>・当法人の事業におけるサービス向上に向けた企画・研究・開発当法人の事業に対する理解を求める活動などの広報活動</p>
            <p>・管理ソフトウエアへの利用登録</p>
            <p>・以上の各事項に関連する業務</p>
            <p>【支援者の皆さまの個人情報】</p>
            <p>・当法人の活動に関する情報提供</p>
            <p>・ボランティアに関するご依頼等の連絡</p>
            <p>・当法人又は当法人の支援者が関係する団体が行う各種イベント、キャンペーン等の開催に関する情報提供</p>
            <p>・当法人の事業におけるサービス向上に向けた企画・研究・開発当法人の事業に対する理解を求める活動などの広報活動</p>
            <p>・皆さまからのお問い合わせに関する対応</p>
            <p>・管理ソフトウエアへの利用登録</p>
            <p>・寄付金に係る領収書の作成及び送付</p>
            <p>・以上の各事項に関連する業務</p>
            <h3> <strong>個人情報の適正な取得</strong></h3>
            <p>当法人は、上記の利用目的のために適正に個人情報を取得します。</p>
            <h3> <strong>個人情報利用の制限</strong></h3>
            <p>当法人は、あらかじめご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。ただし、次に掲げる場合はこの限りではありません。</p>
            <p>(1)法令に基づく場合</p>
            <p>(2)人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</p>
            <p>(3)公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</p>
            <p>(4)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
            <h3> <strong>個人情報利用目的の変更</strong></h3>
            <p>当法人は、個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、ご本人に通知または公表します。</p>
            <h3> <strong>個人情報の管理</strong></h3>
            <p>当法人は、個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・関係者の教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理のための努力を行います。</p>
            <h3> <strong>第三者提供の制限</strong></h3>
            <p>当法人は、次に掲げる場合を除いて、個人情報を第三者に提供しません。</p>
            <p>（1）ご本人の同意がある場合</p>
            <p>（2）利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を外部委託をする場合</p>
            <p>（3）個人情報の保護に関する法律第23条1項各号に該当する場合</p>
            <h3> <strong>開示・訂正・削除請求への対応</strong></h3>
            <p>当法人が保有しているご自身の個人情報の内容についてご確認、または、訂正・削除等をご希望される場合には、ご本人様を確認した上で、誠意を持って速やかに対応いたします。</p>
            <h3> <strong>法令・規範の遵守</strong></h3>
            <p>当法人は、保有する個人情報の取扱いに関して適用される法令、国が定める指針及びその他の規範を遵守いたします。</p>
            <h3> <strong>お問い合わせ</strong></h3>
            <p>当法人の個人情報の取扱いまたは本プライバシーポリシーに関するお問合わせ窓口は、次のとおりです。</p>
            <p>    担　当：一般社団法人 HyogoICT　プライバシーポリシー問い合わせ窓口</p>
            <p>    メール：gi@hict.official.jp</p>
            <h3> <strong>本プライバシーポリシーの変更</strong></h3>
            <p>当法人は、必要に応じて本プライバシーポリシーを変更することがあります。</p>
            <p>制定日：2021年4月5日</p>
        </div>
    </>
   );

   export default PrivacyPage;